"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DfinityWalletProvider = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const React = __importStar(require("react"));
const auth_client_1 = require("@dfinity/auth-client");
const ic_wallet_context_1 = require("../ic-wallet-context");
const reducer_1 = require("../reducer");
const useSafeDispatch_1 = require("../utils/useSafeDispatch");
const ic_wallet_provider_1 = require("../ic-wallet-provider");
const agent_1 = require("@dfinity/agent");
const DfinityWalletProvider = ({ children }) => {
    const [state, unsafeDispatch] = React.useReducer(reducer_1.reducer, ic_wallet_provider_1.INITIAL_STATE);
    const [authClient, setAuthClient] = React.useState();
    const dispatch = (0, useSafeDispatch_1.useSafeDispatch)(unsafeDispatch);
    const { status } = state;
    const synchronize = (dispatch) => {
        if (!authClient) {
            dispatch({ type: 'IcWalletUnavailable' });
            return;
        }
        const fetchWalletState = async () => {
            const isConnected = await authClient.isAuthenticated();
            if (!isConnected) {
                return { type: 'IcWalletNotConnected' };
            }
            const identity = authClient.getIdentity();
            return {
                type: 'IcWalletConnected',
                payload: { account: null, principal: identity.getPrincipal() },
            };
        };
        fetchWalletState()
            .then((ev) => {
            dispatch(ev);
        })
            .catch((e) => {
            console.error("Couldn't fetch wallet state", e);
        });
    };
    const isInitializing = status === 'initializing';
    const isAvailable = status !== 'unavailable' && status !== 'initializing';
    React.useEffect(() => {
        if (isInitializing && !authClient) {
            auth_client_1.AuthClient.create()
                .then((client) => {
                setAuthClient(client);
            })
                .catch((e) => {
                console.error("Couldn't create auth client", e);
            });
        }
        else if (isInitializing && authClient) {
            synchronize(dispatch);
        }
    }, [dispatch, authClient, isInitializing]);
    const connect = React.useCallback(() => {
        if (!isAvailable) {
            console.warn('`connect` method has been called while IcWallet is not available or synchronising. Nothing will be done in this case.');
            return Promise.resolve(null);
        }
        if (!authClient) {
            return Promise.resolve(null);
        }
        // connect
        authClient
            .login({
            identityProvider: 'https://identity.ic0.app',
            onSuccess: () => {
                synchronize(dispatch);
            },
        })
            .then(() => { })
            .catch((e) => {
            console.error("Couldn't connect to wallet", e);
        });
        const fetchAccount = async () => {
            const isConnected = await authClient.isAuthenticated();
            if (!isConnected) {
                return null;
            }
            const identity = authClient.getIdentity();
            return { account: null, principal: identity.getPrincipal() };
        };
        return fetchAccount();
    }, [dispatch, isAvailable]);
    const disconnect = React.useCallback(async () => {
        if (!isAvailable) {
            console.warn('`disconnect` method has been called while IcWallet is not available or synchronising. Nothing will be done in this case.');
            return Promise.resolve(false);
        }
        if (!authClient) {
            return Promise.resolve(false);
        }
        try {
            await authClient.logout();
            dispatch({ type: 'IcWalletNotConnected' });
            return true;
        }
        catch (e) {
            console.error("Couldn't disconnect from wallet", e);
            return false;
        }
    }, [dispatch, isAvailable]);
    const createActor = React.useCallback((canisterId, interfaceFactory, host) => {
        if (!isAvailable) {
            console.warn('`createActor` method has been called while IcWallet is not available or synchronising. Nothing will be done in this case.');
            return Promise.resolve(null);
        }
        if (!authClient) {
            return Promise.resolve(null);
        }
        const identity = authClient.getIdentity();
        const agent = new agent_1.HttpAgent({ identity, host });
        return Promise.resolve(agent_1.Actor.createActor(interfaceFactory, { agent, canisterId }));
    }, [isAvailable]);
    const getBalance = React.useCallback(() => {
        return Promise.resolve(null);
    }, [isAvailable]);
    const value = React.useMemo(() => (Object.assign(Object.assign({}, state), { connect,
        disconnect,
        createActor,
        getBalance })), [state, connect, disconnect, createActor, getBalance]);
    return ((0, jsx_runtime_1.jsx)(ic_wallet_context_1.IcWalletContext.Provider, { value: value, children: children }));
};
exports.DfinityWalletProvider = DfinityWalletProvider;
