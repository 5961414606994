import * as React from 'react';

interface Props
  extends React.JSX.IntrinsicAttributes,
    React.SVGProps<SVGSVGElement> {
  fill: string;
}

const Ekoke = (props: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    data-bbox="162.09 296.62 271.07 248.54"
    data-type="color"
    viewBox="162.09 296.62 271.07 248.54"
    {...props}
  >
    <g fill={props.fill}>
      <path
        d="m382.24 400.39 1.79-1.77s7.1-5.96 18.46-5.96c11.36 0 14.76 5.38 17.89 8.52 5.96 5.97 12.78.57 12.78.57s-13.35-17.61-28.4-17.61c-15.05 0-22.29 3.27-32.09 13.07-9.8 9.8-52.68 52.97-57.94 58.22-5.25 5.25-5.4 22.28-5.4 27.67 0 26.18-23.41 26.14-23.41 0 0-5.4-.14-22.42-5.4-27.67-5.25-5.25-48.14-48.42-57.94-58.22-9.8-9.8-17.04-13.07-32.09-13.07-15.05 0-28.4 17.61-28.4 17.61s6.82 5.4 12.78-.57c3.14-3.14 6.53-8.52 17.89-8.52s18.46 5.96 18.46 5.96l1.79 1.77 47.62 47.16v25.72l-16.76 16.51s-2.56 36.92 42.32 55.38c0 0 2.37-9.94-7.48-13.92-9.85-3.98-26.6-20.17-25.18-38.63l7.1-6.26v6.83s2.65 34.99 36.16 34.99h1.64c33.51 0 36.16-34.99 36.16-34.99v-6.83l7.1 6.26c1.42 18.46-15.34 34.65-25.18 38.63-9.85 3.97-7.48 13.92-7.48 13.92 44.87-18.46 42.32-55.38 42.32-55.38l-16.76-16.51v-25.72l5.13-5.08 42.52-42.08zm-56.71 87.97c0 40.04-55.79 40.61-55.79 0v-31.81s7.1 6.81 7.1 10.51v24.71c0 27.26 41.59 27.26 41.59 0v-24.71c0-3.69 7.1-10.51 7.1-10.51v31.81z"
        data-color={1}
      />
      <path
        d="M302.81 485.52c0-11.36-5.11-23.57 8.52-37.49 13.63-13.91 39.82-32.09 39.82-66.17 0-19.6-21.36-39.76-21.36-39.76s-8.24 4.26 0 14.2 12.5 18.46 12.5 31.24c0 18.35-12.24 32.17-23.86 42.85v-19.28s0-10.51-9.09-10.51v5.68s1.99 15.62-6.53 15.62v-24.71s-.85-9.37 7.81-9.37c0 0-1.11-13.2-13.29-8.31-11.41-4.88-12.52 8.31-12.52 8.31 8.66 0 7.81 9.37 7.81 9.37v24.71c-8.52 0-6.53-15.62-6.53-15.62v-5.68c-9.09 0-9.09 10.51-9.09 10.51v19.28c-11.62-10.68-23.86-24.51-23.86-42.85 0-12.78 4.26-21.3 12.5-31.24 8.24-9.94 0-14.2 0-14.2s-21.36 20.16-21.36 39.76c0 34.08 26.19 52.26 39.82 66.17 13.63 13.92 8.52 26.13 8.52 37.49-.01 14.09 10.19 14.06 10.19 0m6.53-47.08c-6.53 5.73-9.79 10.72-11.42 14.23-.08.18-.15.37-.23.56-.06-.19-.12-.38-.18-.56-1.24-3.66-4.89-8.49-11.42-14.23v-10.01c5.83 3.3 10.34 3.2 11.25 3.14 1.04.06 6.18.16 12.01-3.14v10.01z"
        data-color={1}
      />
      <path
        d="M324.81 323.88c0 15.48-12.91 27.96-28.55 27.24-13.88-.64-25.31-12.06-25.95-25.95-.72-15.64 11.75-28.55 27.23-28.55 15.06 0 27.27 12.21 27.27 27.26"
        data-color={1}
      />
    </g>
  </svg>
);
export default Ekoke;
