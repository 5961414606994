"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IcWalletProvider = exports.WalletProvider = exports.INITIAL_STATE = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const bitfinity_wallet_1 = require("./ic-wallet-provider/bitfinity-wallet");
const plug_1 = require("./ic-wallet-provider/plug");
const dfinity_wallet_1 = require("./ic-wallet-provider/dfinity-wallet");
exports.INITIAL_STATE = {
    status: 'initializing',
    principal: null,
    account: null,
};
var WalletProvider;
(function (WalletProvider) {
    WalletProvider[WalletProvider["Bitfinity"] = 0] = "Bitfinity";
    WalletProvider[WalletProvider["Dfinity"] = 1] = "Dfinity";
    WalletProvider[WalletProvider["Plug"] = 2] = "Plug";
})(WalletProvider || (exports.WalletProvider = WalletProvider = {}));
const IcWalletProvider = ({ children, provider }) => {
    const maybeBitfinityWallet = (0, bitfinity_wallet_1.bitfinityWallet)();
    const maybePlugWallet = (0, plug_1.plugWallet)();
    // with provider
    if (provider === WalletProvider.Bitfinity) {
        return (0, jsx_runtime_1.jsx)(bitfinity_wallet_1.BitfinityWalletProvider, { children: children });
    }
    if (provider === WalletProvider.Plug) {
        return (0, jsx_runtime_1.jsx)(plug_1.PlugWalletProvider, { children: children });
    }
    if (provider === WalletProvider.Dfinity) {
        return (0, jsx_runtime_1.jsx)(dfinity_wallet_1.DfinityWalletProvider, { children: children });
    }
    // without provider
    if (maybeBitfinityWallet) {
        return (0, jsx_runtime_1.jsx)(bitfinity_wallet_1.BitfinityWalletProvider, { children: children });
    }
    if (maybePlugWallet) {
        return (0, jsx_runtime_1.jsx)(plug_1.PlugWalletProvider, { children: children });
    }
    // otherwise return dfinity wallet
    return (0, jsx_runtime_1.jsx)(dfinity_wallet_1.DfinityWalletProvider, { children: children });
};
exports.IcWalletProvider = IcWalletProvider;
