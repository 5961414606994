"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.bitfinityWallet = exports.BitfinityWalletProvider = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const React = __importStar(require("react"));
const principal_1 = require("@dfinity/principal");
const ic_wallet_context_1 = require("../ic-wallet-context");
const reducer_1 = require("../reducer");
const useSafeDispatch_1 = require("../utils/useSafeDispatch");
const ic_wallet_provider_1 = require("../ic-wallet-provider");
const BitfinityWalletProvider = ({ children }) => {
    const [state, unsafeDispatch] = React.useReducer(reducer_1.reducer, ic_wallet_provider_1.INITIAL_STATE);
    const dispatch = (0, useSafeDispatch_1.useSafeDispatch)(unsafeDispatch);
    const { status } = state;
    const synchronize = (dispatch) => {
        const icWallet = (0, exports.bitfinityWallet)();
        if (!icWallet) {
            dispatch({ type: 'IcWalletUnavailable' });
            return;
        }
        const fetchWalletState = async () => {
            const isConnected = await icWallet.isConnected();
            if (!isConnected) {
                return { type: 'IcWalletNotConnected' };
            }
            const account = await icWallet.getAccountID();
            const principal = principal_1.Principal.fromText((await icWallet.getPrincipal()).toString());
            return {
                type: 'IcWalletConnected',
                payload: { account, principal },
            };
        };
        fetchWalletState()
            .then((ev) => {
            dispatch(ev);
        })
            .catch((e) => {
            console.error("Couldn't fetch wallet state", e);
        });
    };
    const isInitializing = status === 'initializing';
    const isAvailable = status !== 'unavailable' && status !== 'initializing';
    React.useEffect(() => {
        if (isInitializing) {
            synchronize(dispatch);
        }
    }, [dispatch, isInitializing]);
    const connect = React.useCallback(() => {
        if (!isAvailable) {
            console.warn('`connect` method has been called while IcWallet is not available or synchronising. Nothing will be done in this case.');
            return Promise.resolve(null);
        }
        const icWallet = (0, exports.bitfinityWallet)();
        if (!icWallet) {
            return Promise.resolve(null);
        }
        // connect
        icWallet
            .requestConnect()
            .then(() => {
            synchronize(dispatch);
        })
            .catch((e) => {
            console.error("Couldn't connect to wallet", e);
        });
        const fetchAccount = async () => {
            const isConnected = await icWallet.isConnected();
            if (!isConnected) {
                return null;
            }
            const account = await icWallet.getAccountID();
            const principal = principal_1.Principal.fromText((await icWallet.getPrincipal()).toString());
            return { account, principal };
        };
        return fetchAccount();
    }, [dispatch, isAvailable]);
    const disconnect = React.useCallback(async () => {
        if (!isAvailable) {
            console.warn('`disconnect` method has been called while IcWallet is not available or synchronising. Nothing will be done in this case.');
            return Promise.resolve(false);
        }
        const icWallet = (0, exports.bitfinityWallet)();
        if (!icWallet) {
            return Promise.resolve(false);
        }
        try {
            await icWallet.disconnect();
            dispatch({ type: 'IcWalletNotConnected' });
            return true;
        }
        catch (e) {
            console.error("Couldn't disconnect from wallet", e);
            return false;
        }
    }, [dispatch, isAvailable]);
    const createActor = React.useCallback((canisterId, interfaceFactory, host) => {
        if (!isAvailable) {
            console.warn('`createActor` method has been called while IcWallet is not available or synchronising. Nothing will be done in this case.');
            return Promise.resolve(null);
        }
        const icWallet = (0, exports.bitfinityWallet)();
        if (!icWallet) {
            return Promise.resolve(null);
        }
        return icWallet.createActor({ canisterId, interfaceFactory, host });
    }, [isAvailable]);
    const getBalance = React.useCallback(() => {
        if (!isAvailable) {
            console.warn('`getBalance` method has been called while IcWallet is not available or synchronising. Nothing will be done in this case.');
            return Promise.resolve(null);
        }
        const icWallet = (0, exports.bitfinityWallet)();
        if (!icWallet) {
            return Promise.resolve(null);
        }
        const getUserAssets = async () => {
            return (await icWallet.getUserAssets()).map((asset) => {
                return {
                    amount: asset.balance,
                    canisterId: asset.canisterInfo.canisterId,
                    decimals: asset.decimals,
                    fee: asset.fee,
                    logo: asset.logo,
                    name: asset.name,
                    standard: asset.standard,
                    symbol: asset.symbol,
                };
            });
        };
        return getUserAssets();
    }, [isAvailable]);
    const value = React.useMemo(() => (Object.assign(Object.assign({}, state), { connect,
        disconnect,
        createActor,
        getBalance })), [state, connect, disconnect, createActor, getBalance]);
    return ((0, jsx_runtime_1.jsx)(ic_wallet_context_1.IcWalletContext.Provider, { value: value, children: children }));
};
exports.BitfinityWalletProvider = BitfinityWalletProvider;
const bitfinityWallet = () => {
    return window.ic && (window.ic.bitfinityWallet || window.ic.infinityWallet);
};
exports.bitfinityWallet = bitfinityWallet;
