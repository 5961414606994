"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reducer = void 0;
function reducer(state, action) {
    switch (action.type) {
        case 'IcWalletUnavailable':
            return {
                principal: null,
                account: null,
                status: 'unavailable',
            };
        case 'IcWalletNotConnected':
            return {
                principal: null,
                account: null,
                status: 'notConnected',
            };
        case 'IcWalletConnected':
            return {
                account: action.payload.account,
                principal: action.payload.principal,
                status: 'connected',
            };
        case 'IcWalletConnecting':
            if (state.status === 'initializing' || state.status === 'unavailable') {
                console.warn(`Invalid state transition from "${state.status}" to "connecting". Please, file an issue.`);
                return state;
            }
            return Object.assign(Object.assign({}, state), { account: null, principal: null, status: 'connecting' });
        // no default
    }
}
exports.reducer = reducer;
